@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/font/ProximaNovaSoft-Semibold.eot');
  src: local('Proxima Nova Soft Semibold'), local('ProximaNovaSoft-Semibold'),
      url('./assets/font/ProximaNovaSoft-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/ProximaNovaSoft-Semibold.woff2') format('woff2'),
      url('./assets/font/ProximaNovaSoft-Semibold.woff') format('woff'),
      url('./assets/font/ProximaNovaSoft-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/font/ProximaNovaSoft-Bold.eot');
  src: local('Proxima Nova Soft Bold'), local('ProximaNovaSoft-Bold'),
      url('./assets/font/ProximaNovaSoft-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/ProximaNovaSoft-Bold.woff2') format('woff2'),
      url('./assets/font/ProximaNovaSoft-Bold.woff') format('woff'),
      url('./assets/font/ProximaNovaSoft-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/font/ProximaNovaSoft-Medium.eot');
  src: local('Proxima Nova Soft Medium'), local('ProximaNovaSoft-Medium'),
      url('./assets/font/ProximaNovaSoft-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/ProximaNovaSoft-Medium.woff2') format('woff2'),
      url('./assets/font/ProximaNovaSoft-Medium.woff') format('woff'),
      url('./assets/font/ProximaNovaSoft-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/font/ProximaNovaSoft-Regular.eot');
  src: local('Proxima Nova Soft Regular'), local('ProximaNovaSoft-Regular'),
      url('./assets/font/ProximaNovaSoft-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/ProximaNovaSoft-Regular.woff2') format('woff2'),
      url('./assets/font/ProximaNovaSoft-Regular.woff') format('woff'),
      url('./assets/font/ProximaNovaSoft-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



