body{
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}
body,
#root{
  height: 100vh;
}
a {
  text-decoration: none;
}
*:focus {
  outline: none;
}
.bg-light{
  background-color: #f5f8fb !important;
}
.link-dark {
  font: normal normal medium 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}

button.swal-button.swal-button--confirm {
  background: transparent linear-gradient(90deg, #E75D0F 0%, #f19702 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  text-decoration: none;
  padding: 12px 10px;
  border-radius: 6px;
  display: block;
  width: 30%;
  margin: 0 auto;
}
button.swal-button.swal-button--confirm:hover {
  background: #4472c7;
  border-color: #4472c7;
}

/* styles.css */

.tooltip-container2 {
  position: relative;
  display: inline-block;
}

.risk-colOne {
  font-size: "15px";
  font-weight: "bold";
  width: "20%";
}

.risk-colTwo {
  font-size: "15px";
  width: "80%";
  text-align: "left";
  overflow-x: "auto";
  white-space: "wrap";
}

.riskWord {
  word-wrap: "break-word";
}

.tooltip2 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  display: none;
}

.tooltip-container2:hover .tooltip2 {
  display: block;
}

.btn-primary,
.form-control,
.searchBar,
.input-1,
.textarea-1,
.rounded-pill,
.nav-link {
  border-radius: 6px !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:active {
  border-color: #E75D0F !important;
  color: #E75D0F !important;
  background-color: #fff !important;
}

.counter-dot {
  color: gray;
  font-size: 11px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  font-weight: bold;
  background: #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleInput {
  border: 0;
  padding: 0.9em 0.7em;
  border-radius: 2px;
  background-color: #f5f8fb;
  color: #000;
  /* height: 43px; */
  height: 43px;
  font-size: 13px !important;
}
.singleInput::placeholder{
  color: rgba(128, 128, 128, 0.671);
  font-size: 13px;
}
.singleInput:focus{
  background-color: #D9D9D9;  
}

.chat-box {
  height: 510px;
  overflow-y: scroll;
}
.swal-button:focus {
  box-shadow: none;
}
.swal-button-container {
  margin: 5px;
  display: block;
  position: relative;
  text-align: center;
}

.swal-modal {
  border-radius: 6px;
}

.btn {
  font: normal normal bold 16px/24px "Open Sans";
  letter-spacing: 0px;
  text-transform: capitalize;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-primary {
  border: none !important;
  background: linear-gradient(90deg, #E75D0F 0%, #f19702 100%);
}
.btn-trinary,
.btn-trinary:hover,
.btn-trinary:focus {
  background-color:#E75D0F;
  color: #fff;
}
.btn-def,
.btn-def:hover{
  background-color: #F19702;
  color: #fff;
}
.text-yellow-dark{
  color: #F19702;
}
.bg-dark-orange{
  background-color: #E75D0F;
}
.bg-orange {
  background-color: #E75D0F;
}
.bg-dark-blue {
  background-color: #168eb4;
}
.bg-dark-red {
  background-color: #b41616;
}
.btn-outline-primary {
  border: 2px solid #E75D0F !important;
  color: #E75D0F;
}
.btn-outline-dark{
border-width: 2px;
}
.btn-dashed-dark,
.btn-dashed-dark:hover,
.btn-dashed-dark:active,
.btn-dashed-dark:focus{
  border: 1px dashed #a0a0a0;
  color: #414141;
}
.fs-16{
  font-size: 16px;
}
.fs-32{
  font-size: 32px;
}
.text-primary {
  color: #E75D0F !important;
}
.text-light-dark{
  color: #414141 !important;
}
.div-text-light-dark .role{
  color: #414141 !important;  
}
.mt-20 {
  margin-top: 20px;
}

.authWrapper {
  background-color: #f5f8fb;
  /* background-image: url("./assets/images/background.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  align-items: center;
  overflow-y: scroll;
}

.mw-480 {
  max-width: 480px;
  width: 100%;
}

.authBox {
  padding: 35px 65px 40px;
  min-height: 587px;
  max-height: 740px;
}

.absolute-center-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 9;
  margin: auto;
}

.wh-114 {
  width: 114px;
  height: 114px;
}

.wh-48 {
  width: 48px;
  height: 48px;
}

.img-contain {
  max-width: 100%;
}

.input-group .btn,
.input-group input,
.input-group select {
  height: 60px;
  padding: 10px 30px;
  justify-content: space-between;
}

.input-group .btn:has(::before),
.input-group input:has(::before),
.input-group .btn:only-child,
.input-group input:only-child {
  justify-content: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.authBody {
  margin-top: 50px;
}

.authFoot {
  margin-top: 60px;
}

.authTitle {
  font: normal normal bold 24px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}

.link-grey {
  font: normal normal medium 17px/24px "Open Sans";
  letter-spacing: 0px;
  color: rgba(65, 65, 65, 0.4);
  text-transform: capitalize;
  text-decoration: none;
}

.modal-rounded .modal-content {
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 6px;
}

.modal-rounded .modal-content .modal-body {
  padding: 35px 20px;
}

.fs-13 {
  font: normal normal normal 13px/16px "Open Sans";
  letter-spacing: 0px;
  color: #000000;
}
.fs-14 {
  font: normal normal normal 14px/18px "Open Sans";
  letter-spacing: 0px;
  color: #000000;
}

.leftSidebar {
  flex: 0 0 217px;
  background-color: #fff;
  /* height: 100vh; */
  max-width: 217px;
  height: 100vh;
}

.rightSidebar {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 230px;
}

.navbar-brand {
  max-width: 110px;
  display: table;
  /* margin-left: auto !important; */
}

.fs-12 {
  font: normal normal 500 12px/14px "Open Sans";
  letter-spacing: 0px;
  color: #ffffff;
}

.navbar {
  padding: 5px 14px;
}

.nav-link {
  color: #000;
  font: normal normal 500 16px/18px "Open Sans";
  letter-spacing: 0px;
  padding: 15px 10px !important;
  width: 100%;
  border-radius: 6px;
  display: flex;
  gap: 11px;
  align-items: center;
  justify-content: end;
  border: 1px solid transparent;
}

.rotateText {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  /* transform: rotate(180deg) translateY(-70px); */
}
/* Legacy vendor prefixes that you probably don't need... */

.nav-link:hover,
.nav-link:active,
.nav-link:focus,
.nav-link.active {
  background: #d9d9d9;
  color: #000 !important;
  border-color: #b1b2b5;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: wrap;
  }
}

.topHeaderWrapper {
  background: #f5f8fb;
  border: 1px solid #f5f8fb;
  padding: 5px 15px;
  border-radius: 6px;
  margin: 0 15px;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerForRiskDetails{
  display: none !important;
}

.title-md {
  font: normal normal bold 25px/34px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.dropdown-menu {
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 6px;
  border: 0;
}

.dropdown-menu a {
  font: normal normal 600 13px/17px "Open Sans";
}

.dropdown-toggle::before,
.dropdown-toggle::after {
  content: none;
}

.w-276 {
  width: 276px;
}

.fs-14-normal {
  font: normal normal 600 14px/17px "Open Sans";
}

.fs-13 {
  font: normal normal bold 13px/14px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.fs-11-06 {
  font: normal normal 500 11px/12px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.6);
}

.fs-11-04 {
  font: normal normal 500 11px/11px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.4);
}

.notificationWrapper {
  padding: 12px;
}

.scroll-wrapper {
  height: 265px;
  overflow-y: scroll;
  padding-right: 10px;
}

.grey-box {
  padding: 13px 20px;
  background: rgba(232, 235, 238, 0.5);
  border-radius: 6px;
}

/* width */
.thin-scroll::-webkit-scrollbar,
.scroll-wrapper::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.thin-scroll::-webkit-scrollbar-track,
.scroll-wrapper::-webkit-scrollbar-track {
  background: #0000001a;
}

/* Handle */
.thin-scroll::-webkit-scrollbar-thumb,
.scroll-wrapper::-webkit-scrollbar-thumb {
  background: #f19702;
  border-radius: 6px;
}

/* Handle on hover */
.thin-scroll::-webkit-scrollbar-thumb:hover,
.scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-35-50 {
  /* padding: 35px 50px; */
  padding: 5px 15px;
}
.seachBarWIthButton{
  /* padding: 0 10px; */
  padding: 0 0;
}
.searchBar {
  background-image: url("./assets/icons/search.svg");
  background-size: 12px;
  background-position: 15px 50%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border: 1px solid rgba(201, 201, 201, 0.5);
  border-radius: 6px;
  height: 48px;
  padding: 16px 44px;
  font: normal normal 600 13px/20px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  outline: none;
  width: 19.5em;
}

.searchBar::placeholder {
  color: rgba(65, 65, 65, 0.5);
}

.h-48 {
  height: 48px !important;
}

.custom-border-1 {
  background: #fff7eb;
  border: 1px solid #f9cdb0;
  border-radius: 6px;
  padding: 23px 17px 17px;
}
.custom-border-2 {
  background: #fff7eb;
  border: 1px solid #f9cdb0;
  border-radius: 6px;
  padding: 17px 10px;
}
.custom-border-3 {
  background: #f5f8fb;
  border: 1px solid #E75D0F;
  border-radius: 6px;
  padding: 23px 17px 17px;
}
.custom-border-4 {
  background: #f5f8fb;
  border: 1px solid #414141;
  border-radius: 6px;
  padding: 23px 17px 17px; 
}
.text-dark,
.text-414141{
  color: #414141 !important;
}
.custom-border-5 {
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 15px 17px 15px; 
}
.Short-Alphabate {
  width: 65px;
  height: 65px;
  background: #fbddc7;
  border-radius: 50%;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 21px/29px "Open Sans";
  letter-spacing: 0px;
  color: rgba(231, 94, 15, 0.8);
}

.sm-Alphabate {
  flex: 0 0 53px;
  width: 53px;
  height: 53px;
  background: #fbddc7;
  border-radius: 50%;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(231, 94, 15, 0.8);
}

.custom-flex-row {
  gap: 30px;
}

.column-5 {
  flex: 0 0 calc(20% - 25px);
  padding-left: 0;
  padding-right: 0;
  width: calc(20% - 25px);
}

.fs-18-bold-08 {
  font: normal normal 600 18px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.fs-12-medium-08 {
  font: normal normal 600 12px/20px "Open Sans";
  letter-spacing: 0px;
  color: rgba(231, 94, 15, 0.8);
}

.contractEdit {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 9;
  border-radius: 6px;
  display: inline-block;
}

.modal-600 {
  max-width: 600px;
  /* width: 100%; */
}
.modal-700 {
  max-width: 700px;
  /* width: 100%; */
}

.modal-650 {
  max-width: 650px;
  /* width: 100%; */
}

.modal-350 {
  max-width: 350px;
  /* width: 100%; */
}
.modal-280 {
  max-width: 280px;
  /* width: 100%; */
}
.modal-230 {
  max-width: 230px;
  /* width: 100%; */
}
.abolute-close .btn-close {
  position: absolute;
  right: 20px;
  top: 15px;
  margin: 0;
  background: url("./assets/icons/close-invert.svg");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  z-index: 2;
}

.fs-21-600 {
  font: normal normal 600 21px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}

.input-1 {
  height: 48px;
  background: #f5f8fb;
  border: 1px solid #f5f8fb !important;
  border-radius: 6px;
  text-align: left;
  font: normal normal 400 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  padding: 20px 25px;
}

.textarea-1 {
  background: #f5f8fb;
  border: 1px solid #f5f8fb;
  border-radius: 6px;
  font: normal normal 400 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  padding: 20px;
}
.textarea-1:focus,
.textarea-1:focus-visible {
  border: 1px solid inherit;
  outline: none;
}



input[type=date]:invalid::-webkit-datetime-edit,
.input-1::placeholder, .textarea-1::placeholder{
  font-style: italic;  
  font-weight: 400;
  font-size: 16px;
  color: #dadada;
}
.input-2 {
  height: 48px !important;
  background: #f5f8fb;
  border: 1px solid #f5f8fb !important;
  text-align: left;
  font: normal normal 400 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  padding: 20px 25px;
}

.textarea-2 {
  background: #f5f8fb;
  border: 1px solid #f5f8fb;
  font: normal normal 400 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  padding: 20px;
}
.textarea-2:focus,
.textarea-2:focus-visible {
  border: 1px solid inherit;
  outline: none;
}

input[type="date"],input[type="time"]{
  text-align: center;
  font-size: 16px;
}

.dropWithArrow {
  font: normal normal 600 15px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(65, 65, 65, 0.5);
  display: flex;
  justify-content: space-between;
}

.dropWithArrow::after {
  content: "";
  background-image: url("./assets/icons/down.svg");
  background-repeat: no-repeat;
  height: 5px;
  width: 6px;
  background-size: contain;
  border: 0;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.btn.show,
.dropWithArrow:hover {
  background-color: #fff;
  color: #414141;
}

.dropWithArrow2,
.dropWithArrow3 {
  font: normal normal 400 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(65, 65, 65, 0.5);
  display: flex;
  justify-content: space-between;
}

.dropWithArrow2::after,
.dropWithArrow3::after {
  content: "";
  background-image: url("./assets/icons/down-angle.svg");
  background-repeat: no-repeat;
  height: 13px;
  width: 16px;
  background-size: contain;
  border: 0;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.dropWithArrow3::after{
  background-image: url("./assets/icons/down-angle2.svg");  
  right: 0;
}
.dropWithArrow3:hover,
.dropWithArrow3:focus,
.dropWithArrow3:active,
.dropWithArrow2:hover,
.dropWithArrow2:focus,
.dropWithArrow2:active {
  background-color: #f5f8fb;
  color: #000;
}
button.dropWithArrow2{
  color: #414141;
}

.br-14 {
  border-radius: 6px;
}
.rounded-3px{
  border-radius: 3px !important;
}
.dropdown-menu input[type="search"] {
  background-image: url("./assets/icons/search.svg");
  background-size: 10px;
  width: 100%;
  background-position: 15px 50%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border: 1px solid rgba(201, 201, 201, 0.5);
  border-radius: 6px;
  height: 30px;
  padding: 9px 10px 9px 30px;
  font: normal normal 600 13px/20px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  outline: none;
}
.dropdown-menu input[type="search"].searchRight{
  background-position: 90% 50%;  
  padding: 9px 30px 9px 10px;
  border: 2px solid #bbbbbb;
  border-radius: 5px;
}

.dropdown-menu input[type="search"]::placeholder {
  color: rgba(65, 65, 65, 0.5);
}

.customRadio {
  cursor: pointer;
}

.customRadio input[type="radio"] {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.customRadio input[type="radio"]::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid #b5b5b5;
  border-radius: 6px;
  display: inline-block;
}

.customRadio input[type="radio"]:checked::before {
  border: 1px solid #E75D0F;
}

.customRadio input[type="radio"]:checked::before {
  border: 1px solid #E75D0F;
  background-color: #E75D0F;
  box-shadow: inset 0px 0px 0 1px #fff;
}

.customRadio h6 {
  font: normal normal 600 14px/18px "Open Sans";
  letter-spacing: 0px;
  color: rgb(0 0 0 / 80%);
  /* flex: 0 0 140px; */
  white-space: pre-line;
}

.customRadio img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.customRadio h6 small {
  font: normal normal 600 11px/20px "Open Sans";
  letter-spacing: 0px;
  color: rgb(87 87 87 / 50%);
}

.scroll-120 {
  height: 120px;
  overflow-y: auto;
  padding-right: 5px;
}

.scroll-120 label {
  margin-bottom: 11px;
}

.fs-12-600-link {
  font: normal normal 600 12px/17px "Open Sans";
  letter-spacing: 0px;
  color: #E75D0F;
}

.h-55 {
  height: 48px;
}

.fs-16 {
  font: normal normal 600 16px/22px "Open Sans";
  letter-spacing: 0px;
}

.br-10 {
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #efefef;
}

.table-responsive{
  min-height: 226px;
  max-height: fit-content;
}

.table-style-1 thead tr th {
  font: normal normal bold 16px/18px "Open Sans";
  letter-spacing: 0px;
  color: #fefbf7;
  background-color: #F19702;
  padding: 24px 10px 24px;
  white-space: nowrap;
  vertical-align: top;
  border: 0;
}
.table-style-1 thead tr th:not(:first-child),
.table-style-1 tbody tr td:not(:first-child) {
  text-align: center;
}
.table-style-1 tbody tr td {
  font: normal normal 500 11px/15px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  background-color:  #fffffe;
  padding: 25px 15px 25px;
  white-space: nowrap;
  border: 0;
  vertical-align: middle;
}
.table-bg-2 thead tr th{
  background-color: #d9d9d9;
}
.table-bg-3 thead tr th{
  background-color: #d9d9d9;
}
.table-style-1 tbody tr:nth-child(even) td {
  background: #fafbfc;
}
.table-style-1 thead tr th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.table-style-1 thead tr th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  text-align: right;
}
.table-style-1 tbody tr td:last-child{
  text-align: right;  
}
.showResult {
  font: normal normal 600 15px/18px "Open Sans";
  letter-spacing: 0px;
  color: rgba(26, 26, 26, 0.5);
}

.pagination {
  gap: 13px;
}

.page-link {
  background: #ffce0a00;
  border: 2px solid #33322f;
  border-radius: 6px;
  font: normal normal 600 13px/16px "Open Sans";
  letter-spacing: 0px;
  color: #33322f;
  height: 38px;
  width: 38px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  width: auto;
}

.page-item.active .page-link {
  background: #E75D0F;
  border-color: #E75D0F;
  color: #fff;
}

.smEdit {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.column-3 {
  flex: 0 0 20%;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contract-para {
  font: normal normal 500 11px/18px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}

.userContentFirst {
  font: normal normal 600 12px/12px "Open Sans";
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 3px;
}

.userContentSecond {
  font: normal normal normal 10px/12px SF Pro Display;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 0;
}

.userContractImage {
  width: 21px;
  height: 21px;
  border-radius: 50%;
}

.column-2_5 {
  flex: 0 0 13%;
}

.custom-row > * {
  position: relative;
}

.custom-row > *:not(:last-child):before {
  content: "";
  position: absolute;
  right: -6px;
  height: 100%;
  width: 1px;
  background-color: #E75D0F;
  opacity: 0.3;
  top: 0;
  bottom: 0;
}

.heading-sm {
  font: normal normal 600 21px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}

label {
  font: normal normal 500 16px/14px "Open Sans";
  letter-spacing: 0px;
  color: black
}

.btn-group {
  padding: 5px 0;
  border: 0px solid #1b1d2180 !important;
  gap: 15px;
  overflow-x: auto !important;
  padding-bottom: 5px !important;
}
.btn-group select,
.btn-group input {
  font: normal normal 400 16px/19px "Open Sans";
  letter-spacing: 0px;
  background-color: #f5f8fb;
  color: #000000;
  height: 48px;
  flex: 0 0 48px;
  padding-left: 5px;
  padding-right: 5px;
  width: inherit;
  border-radius: 6px !important;
}

.btn-group input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.form-control,
.form-control:hover,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.chooseColor {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 3px;
}

.box{
  border-radius: 6px;
}

.bg-red {
  background-color: #B00126;
}

.bg-green {
  background-color: #3A7557;
}

.bg-light-green {
  background-color: #00BF63;
}

.bg-yellow {
  background-color: #F1CB02;
}

.bg-amber {
  background-color: #E75D0F;
}

.w-260 {
  width: 260px;
}

.colorScheme.show {
  display: flex;
  flex-wrap: wrap;
}

.colorScheme.show > * {
  display: inline-block;
  width: auto;
  flex: 0 0 auto;
}

.pillsTabs {
  border: 0;
  gap: 5px;
  display: inline-flex;
}
.pillsTabs .nav-item{
  flex: 1 0 7em;
  width: 7em;
} 
.pillsTabs .nav-link {
  /* min-width: 109px;
  max-width: 189px; */
  height: 48px;
  background: #f5f8fb 0% 0% no-repeat padding-box;
  font: normal normal 400 16px/20px "Open Sans";
  letter-spacing: 0px;
  color: #000;
  border: 0;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.pillsTabs .nav-link.active,
.pillsTabs .nav-link.active:hover {
  background: #E75D0F;
  color: #fff !important;
}
.pillsTabs .nav-link:hover{
  color: #000 !important;
}
.wh-12 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}
.right-0 {
  font: normal normal normal 13px/22px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
  padding-right: 10px !important;
}
.right-0::after {
  right: 0;
}

.border-style-2 {
  background: rgba(236, 236, 236, 0.32);
  border: 1px solid rgba(141, 141, 141, 0.32);
  border-radius: 6px;
  padding: 17px 14px 13px;
}
.custom-flex-15-gap {
  gap: 15px;
}
.custom-flex-15-gap > * {
  flex: 0 0 calc(20% - 15px);
}

.customRadio input[type="checkbox"] {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.customRadio input[type="checkbox"]::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid #b5b5b5;
  display: inline-block;
}

.customRadio input[type="checkbox"]:checked::before {
  border: 1px solid #E75D0F;
}

.customRadio input[type="checkbox"]:checked::before {
  content: "\2713";
  font-size: 6px;
  font-weight: bold;
  color: #E75D0F;
  border: 1px solid #E75D0F;
  box-shadow: inset 0px 0px 0 1px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-heading {
  font: normal normal bold 19px/22px "Open Sans";
  letter-spacing: 0px;
  color: #E75D0F;
}
.fs-17-600 {
  font: normal normal 600 17px/20px "Open Sans";
}
.accordion-item,
.accordion-item-header,
.accordion-item-body {
  border: 0 !important;
}
.accordion-header button {
  border: 0 !important;
  background: rgba(249, 205, 176, 0.2) !important;
  border-radius: 6px !important;
  font: normal normal bold 19px/34px "Open Sans";
  color: rgba(0, 0, 0, 0.8);
  padding: 33px 35px;
  box-shadow: none !important;
}
.changePassword {
  padding: 70px 65px;
  background: rgba(249, 205, 176, 0.1);
  border-radius: 6px;
}
.fs-23-bold {
  font: normal normal bold 23px/27px "Open Sans";
  letter-spacing: 0px;
  color: #414141;
}
input[disabled="disabled"] {
  background-color: #f1f1f1 !important;
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #651fff;
}

.main {
  background-color: #eee;
  position: relative;
  border-radius: 6px;
  padding: 6px 0px 0px 0px;
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 65vh;
}

.img1 {
  border-radius: 50%;
}

.name {
  font-size: 8px;
}

.msg {
  background-color: #fff;
  font-size: 11px;
  padding: 5px;
  border-radius: 6px;
  font-weight: 500;
  color: #3e3c3c;
}

.between {
  font-size: 8px;
  font-weight: 500;
  color: #a09e9e;
}

.form-control {
  font-size: 12px;
  font-weight: 400;
  width: 230px;
  height: 30px;
  border: none;
}

.form-control:focus {
  box-shadow: none;
  overflow: hidden;
  border: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.icon1 {
  color: #7c4dff !important;
  font-size: 18px !important;
  cursor: pointer;
}

.icon2 {
  color: #512da8 !important;
  font-size: 18px !important;
  position: relative;
  left: 8px;
  padding: 0px;
  cursor: pointer;
}

.icondiv {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 2px;
  position: relative;
  bottom: 1px;
}
.form-switch .form-check-input{
  width: 2.1em;
  height: 1.1em;
}
.form-check-input:focus{
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #f06627;
  border-color: #f06627;
}
.border-style-3 {
  background: rgba(254, 248, 243, 0.45);
  border: 1px solid rgba(231, 94, 15, 0.45);
  border-radius: 6px;
  padding: 25px;
}
.assessmentAnalysis {
  background: rgba(249, 205, 176, 0.3);
  border-radius: 6px;
  padding: 30px;
}
.fs-16-06 {
  font: normal normal 600 16px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(52, 52, 52, 0.6);
}
.idWithInfo {
  background: #fff7eb;
  border: 1px solid #f9cdb0;
  border-radius: 6px;
  padding: 18px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.idWithInfo > span > small {
  font: normal normal 600 12px/20px "Open Sans";
  letter-spacing: 0px;
  color: rgba(231, 94, 15, 0.8);
}
.idWithInfo > span {
  display: flex;
  flex-direction: column;
  font: normal normal 600 17px/22px "Open Sans";
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
}
.idWithInfo > span:not(:last-child):before {
  content: "";
  position: absolute;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  right: -10px;
  height: 100%;
  width: 1px;
}
.dropWithArrow:focus {
  background-color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("./assets/icons/downarrow.svg");
}
.accordion-button::after {
  background-image: url("./assets/icons/downarrow.svg");
}
.offcanvas.offcanvas-end {
  margin-top: 10px;
}

.badge-success {
  border: 1px solid #77dd77;
  color: #ffffff;
  background-color: #77dd77;
}
.badge-danger {
  border: 1px solid red;
  color: #ffffff;
  background-color: red;
}
.badge {
  border-radius: 5rem;
  font-size: 12px;
  font-weight: initial;
  line-height: 1;
  padding: 0.2rem 0.5rem;
  font-family: "Poppins", sans-serif;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #00FF00;
  color: #111;
  font-size: 150%;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.navbar-toggler-icon {
  filter: invert(1);
}
.nameCircle{
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background: #E75D0F;
  color: #fff;
}
.noBeforeAfter::before,
.noBeforeAfter::after{
  content: none !important;
}
.upateProfileWrap{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.upateProfileWrap > label{
  position: absolute;
  bottom: 0;
  right: 0;
}

.position-absolute-required{
  position: absolute;
  top: 5px;
  left: calc(262px + 13px);
}
.position-absolute-required:has(button#uncontrolled-tab-example-tab-Reports.active) {
  left:12px;
}
.searchOnLeft{
  position: absolute;
  top: 5px;
  right: auto;
  left: 12px;
}
#burst-12 {
  background: #414141;
  width: 20%;
  height: 20%;
  position: relative;
  text-align: center;
  }
  #burst-12:before, #burst-12:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #414141;
  }
  #burst-12:before {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  }
  #burst-12:after {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  }
  .p-30{
    padding: 0 0 45px 45px;
  }
  .flex1 div p {
    flex: 1;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .hoverShow{
    opacity: 0; 
    cursor: pointer;
  }
  .hoverShow:hover{
    opacity: 1; 
  }


  p.text-center.m-0.right-arrow {
    position:relative;
    padding-top: 7px;
}
p.text-center.m-0.right-arrow::before {
    content:'';
    position:absolute;
    top:3px;
    width:99%;
    height:1px;
    background-color:#000;
    left:0;
    right:0;
}
p.text-center.m-0.right-arrow::after {
    content:'';
    position:absolute;
    border-top: 4px solid transparent;
	border-left: 7px solid #000;
	border-bottom: 4px solid transparent;
    right:0px;
    top:0;
}


p.m-0.top-arrow {
    position:relative;
    padding-left: 7px;
}
p.m-0.top-arrow::before {
    content:'';
    position:absolute;
    bottom:4px;
    width:1px;
    height: 99%;
    background-color:#000;
    left:3px;
}
p.m-0.top-arrow::after {
  content: '';
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 7px solid #000;
  bottom: 2px;
  transform: rotate(180deg);
  left: 0;
}

.martrix-arrow .top-arrow::after{
  left: 0px !important;
}
.martrix-arrow .top-arrow::before{
  left: 0px !important;
  height: 94% !important;
  right: 0;
  margin: auto;
}


.martrix-arrow .right-arrow::after{
  right: 9px !important;
}
.martrix-arrow .right-arrow::before{
  width: 92% !important;
  left: unset !important;
  right: 13px !important;
}

.floatEnd input{
  float: right !important;
}
.label-dark label{
  color: #000 !important;
}
.cr-arrow-top.martrix-arrow .top-arrow::after{
  left: 11px !important;
}
.cr-arrow-top.martrix-arrow .top-arrow::before {
  height: 92% !important;
}
.cr-arrow-top.martrix-arrow .right-arrow::before {
  width: 89% !important;
}
.section-heading{
  font-size: 32px;
  font-weight: 700;
  color: #414141; 
}
label.cf_label{
  font-size: 16px;
  font-weight: 400;
  color: #414141; 
  line-height: inherit;
}
.createMatrixEmpty{
  width: max-content;
}
.createMatrixEmpty {
  height: fit-content;
  max-width: 100px;
  width: 100%;
  text-align: center;
  color: #414141; 
}
.title-head{
  font-size: 16px;
  line-height: inherit;
  color: #414141; 
  font-weight: bold;
}
.h-fit-col{
  height: fit-content !important;
}
.h-80{
  height: 72px;
}
.emptyMartix{
  max-width: 18em;
  max-height: 18em;
  width: 100%;
  height: 100%;
  margin-left: auto;
}
.emptyMartix > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.equal-col-3 > *{
  flex: 0 0 30%;
}
.wh-315{
  max-width: 19em;
  width: 100%;
  height: 19em;
}
.wh-17{
  max-width: 17em;
  width: 100%;
  max-height: 17em;
  height: 100%;
}
.w-17{
  max-width: 15em;
  width: 100%;  
}
.w-315{
  /* max-width: 315px; */
  max-width: 19em;
  width: 100%;
}
.w-18{
  max-width: 18em;  
  width: 100%;
}
.fs-11{
  font-size: 11px !important;
}
.short-para{
    word-break: break-word;
    white-space: break-spaces;
    margin: auto;
    width: 200px;
    text-align: center;
}
.contractFile{
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: auto;
  z-index: 999;
}
.contractFile > img{
  width: 100%;
  height: 100%;
}
/* .custom-grid-col-8-count{
  display: flex;
  grid-gap: 33px;
  flex-wrap: wrap;
} */
.custom-grid-col-8-count {
  display: grid;
  grid-gap: 33px;
  flex-wrap: wrap;
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
/* .custom-grid-col-8-count > *{
  max-width: 147px;
  flex: 1 0 147px;
} */
.flex-5-fill {
  display: flex;
}
.flex-5-fill > * {
  flex: 1 0 50%;
}
.bg-even-odd.nav-pills .nav-link{
  border: 0;
  border-radius: 0 !important;
  padding: 27px 10px !important;
  justify-content: center;
}
.bg-even-odd.nav-pills .nav-link.active{
  background-color:#f1f1f1;  
}
.br-6{
  border-radius: 6px !important;
}
.bg-tab-1{
  background-color: #ececec;  
}
.bg-tab-2{
  background-color: #fafbfd;  
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  background-color: #f5f8fb;
}
.modal .cf_label{
  color: #000;
}
.bg-black{
  opacity: 1;
}
.matrixLabel label{
  font-size: 14px;
}
.accessGranted{
  border: 0 !important;
  color: #F19702;
  font-size: 16px;
  font-weight: bold !important;
  position: relative;
}
.bg-light-gray{
  background-color: #f0f0f0;
}
.top-arrow1{
  position:relative;
}
.top-arrow1:after,
.top-arrow1:before{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}
.top-arrow1[x-placement="top-start"]:after {
  border-top: 15px solid #f0f0f0;
  bottom: -15px;
}
.top-arrow1[x-placement="bottom-start"]:before {
  border-bottom: 15px solid #f0f0f0;
  top: -15px;
}
.scroll-label-hover:has(:checked),
.scroll-label-hover:hover{
  background-color: #f1f1f1;
}
.vr-line{
  position: relative;
}
.vr-line::before{
  content: '';
  position: absolute;
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 10px;
  height: 4em;
  background-color: #fff;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.shadow3{
  box-shadow: 3.5px 3.5px 2px 3px rgba(0, 0, 0, 0.35);
}
.wh-small{
  width: 10em;
  height: 10em;
}
.sm-matrix-project .box{
  border: 1px solid #d9d9d9 !important;
}
.w-11{
  width: 9.5em;
}
.outer-div-text-gray .role{
  color:#414141 !important;
}
.seachBarWIthButton{
  height: 48px;
}
.modal-800{
  max-width: 800px;
  width: 95%;
}
.navbar-toggler{
  padding: 0;
  border-radius: 3px;
}
.h-147{
  height: 147px;
}

.cms-tabs.pillsTabs .nav-item {
  flex: 1 0 10em;
  width: 10em;
}
.leftSidebar,
.innerPagesWrapper{
  padding-top: 52px;
}
.topHeaderWrapper.sticky-top{
  top: 52px;
}
@media (min-width:1199px) and (max-width:1599px){
  .custom-grid-col-8-count {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 20px;
  }
}
@media (max-width:1300px){
  .h-48 {
    height: 40px !important;
  }
  .section-heading {
    font-size: 25px;
  }
  .input-1,
  label,
  .fs-16,
  .textarea-1,
  .nav-link,
  .fs-14,
  label.cf_label {
    font-size: 12px;
  }

  .fs-16 {
    font: normal normal 600 12px / 22px "Open Sans";
  }
  .dropWithArrow2, .dropWithArrow3 {
    font: normal normal 400 12px / 22px "Open Sans";
  }
}

@media (max-width:1199px){
  .seachBarWIthButton.justify-content-end{
    justify-content: space-between !important;

  }
  .position-absolute-required{
    margin-top: 5px;
    position: relative;
    top: 0;
  }
  .searchBar {
    width: auto;
  }

    .custom-border-5 > *.vr-line:first-child::before {
      content:none;
    }
    .custom-grid-col-8-count {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-gap: 15px;
    }
  .custom-flex-row {
    gap: 20px;
  }
  .column-5 {
    flex: 0 0 calc(25% - 15px);
    width: calc(25% - 15px);
  }
}
@media (max-width:991px){
  .p-35-50 {
    padding: 15px 25px;
  }
  .leftSidebar {
    flex: 0 0 100%;
    height: auto;
    max-width: 100%;
  }
  .offcanvas.offcanvas-end {
    margin-top: 0;
  }
  /* .offcanvas{
    background-color: #230d00;
  } */
  .btn-close{
    background-color: #fff;
  }
  .offcanvas-backdrop {
    width: 100vw;
    height: 100%;
  }
  .rightSidebar {
    padding-left: 0;
  }
  .leftSidebar {
    flex: 0 0 auto;
    position: unset !important;
  }
  .navbar-brand {
    width: 60px;
    height: 60px;
  }
  .navbar {
    padding: 10px 25px;
  }
  .custom-flex-row {
    gap: 15px;
  }
  .column-5 {
    flex: 0 0 calc(33.3% - 10px);
    width: calc(33.3% - 10px);
  }
  /* .authWrapper{
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background: none;
    background-color: #f5f2ea;
    align-items: start;
  } */
  .mw-480{
    margin: auto;
  }
  .leftSidebar,
  .innerPagesWrapper{
    padding-top: 0;
  }
}
@media (max-width:992px){
  .title-head {
    font-size: 12px;
  }
  .section-heading {
    font-size: 25px;
  }
  /* .input-1,
  label,
  label.cf_label,
  .fs-16,
  .textarea-1 {
    font-size: 14px;
    line-height: 19px;
  } */
  .btn {
    font: normal normal bold 14px/20px "Open Sans";
  }
}

@media (max-width:767px){
  .topHeaderWrapper.sticky-top {
    top: 10px;
  }
  
  .col-md-12:has(.h-80) {
      display: none;
  }
  .col-md-9.border-end{
    border: 0 !important;
  }
  .heading-sm {
    font: normal normal 600 18px/22px "Open Sans";
  }
  .input-group .btn, .input-group input, .input-group select {
    height: 50px;
  }
  .custom-flex-row {
    gap: 10px;
  }
  .column-5 {
    flex: 0 0 calc(50% - 5px);
    width: calc(50% - 5px);
  }
  /* .p-35-50 {
    padding: 35px 25px;
  } */
  .column-3 {
    flex: 1 0 20%;
  }
  
  .seachBarWIthButton{
    flex-wrap: wrap;
    gap: 15px;
  }
  .seachBarWIthButton > *{
    justify-content: center;
    flex: 1 0 auto;
  }
  .custom-grid-col-8-count {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px;
  }
  .searchBar,
  .position-absolute-required{
    position: unset;
    left: 0;
  }
  .position-absolute-required{
    width: 100%;
  }
}
@media (max-width:575px){
  .seachBarWIthButton{
    height: auto;
  }
  .table-responsive {
    min-height: 155px;
  }
  .upateProfileWrap {
    width: 120px;
    height: 120px;
  }
  .changePassword {
    padding: 50px 25px;
  }
  .mobile-48{
    flex-wrap: wrap;
    width: 100%;
  }
  .mobile-48 > *{
    flex: 1 0 48%;
  }
  .nameCircle{
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .column-5 {
    flex: 0 0 calc(100%);
    width: calc(100%);
  }
  .navbar {
    padding: 10px 15px;
  }
  .title-md {
    font: normal normal bold 19px/25px "Open Sans";
  }
  .searchBar {
    padding: 16px 34px;
    width: 100%;
  }
  
  .custom-grid-col-8-count {
    grid-gap: 24px;
  }
  .custom-border-5 > *.vr-line:first-child::before {
    content:'';
}
.custom-border-5 > *.vr-line::before {
    width:25%;
    height:2px;
    min-height:2px;
    top:auto;
    bottom:-15px;
    left:0;
}
.custom-grid-col-8-count {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 15px;
}
.flex-5-fill > * {
  flex: 1 0 100%;
}
.bg-even-odd.nav-pills .nav-item:nth-child(even) .nav-link{background-color: #fafbfd;}
.bg-even-odd.nav-pills .nav-item:nth-child(odd) .nav-link{background-color: #ececec;}
.position-absolute-required:has(button#uncontrolled-tab-example-tab-Reports.active) {left: 0;}
}
@media (max-width:480px){
  .matrixLabel label ,
  .matrixLabel span{
    font-size: 10px !important;
  }
  .seachBarWIthButton:has(div:empty) input{
      width:100% !important;
  }
}
@media (max-width:380px){
  .custom-grid-col-8-count {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
  }
}

/* @media print {
.print-report{
  padding: 10px;
}
.print-report table{
  page-break-inside: avoid;
}
}
@page{
  size: A4;
  margin-top: 50px;
  margin-bottom: 50px;
}
.nodatafound,table td, table th {
  page-break-inside: avoid;
} */

.footerForPDF {
 margin-top: 107%;
}

.actiontd td {
  padding: 0%!important;
}
/* @media (min-width:767px) and (max-width: 991px) {
  .table-style-1 thead tr th,
  .table-style-1 tbody tr td {
    padding: 14px 10px;
  }  
  .short-para {
    width: 90px;
  }
  #table-to-xls td[style*="font-size: 15px"] {
    font-size: 11px !important;
    line-height: 12px;
    font-weight: 500 !important;
  }
  #table-to-xls tbody td {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 11px;
  }
  #table-to-xls tbody td .actionId {
    max-width: 80px;
    text-wrap: wrap;
    line-height: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 9px;
  }
} */

/* @media (min-width:991px) and (max-width: 1280px) {
  .table-style-1 thead tr th,
  .table-style-1 tbody tr td {
    padding: 14px 10px;
  }  
  .short-para {
    width: 90px;
  }
  #table-to-xls td[style*="font-size: 15px"] {
    font-size: 11px !important;
    line-height: 12px;
    font-weight: 500 !important;
  }
  #table-to-xls tbody td {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 11px;
  }
  #table-to-xls tbody td .actionId {
    max-width: 80px;
    text-wrap: wrap;
    line-height: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 9px;
  }
} */

.table-responsive-th tr th{
  padding: 5px !important;
  font-size: 11px !important;
}

.respo-td td{
  padding: -100px !important;
}

#riskProximityAnalysis{
  font-family: "Open Sans", sans-serif !important;
  margin-top: 50px;
  height: 1200px !important;
}

.fontinRiskProxi th{
  /* font-family: "Open Sans", sans-serif !important; */
  font-size: 13px !important;
  padding: 7px 10px 7px !important;
}

.toprisktable td{
  text-align: center !important;
}

.toprisktable th{
  text-align: center !important;
}